<template>
<div v-if="items != 0">
  <h5 class="titulo-lista">Enlaces</h5>
  <ul class="lista-links-public">
    <li v-for="i in items" :key="i.key">
      <a class="link-public" :href="i.url">
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-link" viewBox="0 0 16 16">
          <path d="M6.354 5.5H4a3 3 0 0 0 0 6h3a3 3 0 0 0 2.83-4H9c-.086 0-.17.01-.25.031A2 2 0 0 1 7 10.5H4a2 2 0 1 1 0-4h1.535c.218-.376.495-.714.82-1z"/>
          <path d="M9 5.5a3 3 0 0 0-2.83 4h1.098A2 2 0 0 1 9 6.5h3a2 2 0 1 1 0 4h-1.535a4.02 4.02 0 0 1-.82 1H12a3 3 0 1 0 0-6H9z"/>
        </svg>
        {{i.nombre}}
      </a>
    </li>
  </ul>
</div>
  
</template>

<script>
import firebase from "../firebase";

export default {
  name: "PubLinksLista",
  props: ["seccion", "subseccion", "publicacion"],
  data() {
    return {
      ref: firebase
        .firestore()
        .collection("secciones")
        .doc(this.seccion)
        .collection("subsecciones")
        .doc(this.subseccion)
        .collection("publicaciones")
        .doc(this.publicacion)
        .collection("links"),
      items: {},
    };
  },
  created() {
    this.ref.orderBy("orden").onSnapshot((querySnapshot) => {
      this.items = [];
      querySnapshot.forEach((doc) => {
        this.items.push({
          key: doc.id,
          nombre: doc.data().nombre,
          url: doc.data().url,
          orden: doc.data().orden,
        });
      });
      //console.log(this.items)
    });
  },
};
</script>

<style lang="scss" scoped>
@import "../scss/abstracts/variables";

  .titulo-lista {
    font-size: 1rem;
      text-transform: uppercase;
  }
  .lista-links-public {
    margin-bottom: 2rem;
    padding-left: 2rem;
  }

  .link-public {
    text-decoration: none;
    color: $color-text;
    svg {
      width: 1.75rem;
      height: 1.75rem;
    }
    &:hover {
      svg {
        fill: $color-01;
      }
    }
  }
</style>
